import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import {
  customerIdSelector,
  emailSelector,
  marketingSelector
} from '@/lib/store/slice/customer'
import { isGlobalECheckoutSelector } from '@/lib/store/slice/intl'
import checkoutRequest from '../api/checkout'
import { submittedSelector, setSubmitted } from '@/lib/store/slice/summary'
import useBasket from './useBasket'
import useNav from './useNav'
import getProduct from '@/lib/storefront/get-product'
import { useEffect, useState } from 'react'
import { getVariationStock } from '@/lib/stock'
import useGlobaleCountry from '@/hooks/intl/useGlobaleCountry'

const useCheckout = () => {
  // Data / states
  const dispatch = useDispatch()
  const router = useRouter()
  const countryCode = useGlobaleCountry()
  const basket = useBasket()
  const [closeAll] = useNav()
  const [productsFailed, setProductsFailed] = useState([])
  const [stockChecked, setStockChecked] = useState(false)

  // Selectors
  const isGlobalECheckout = useSelector(isGlobalECheckoutSelector)
  const inProgress = useSelector(submittedSelector)
  const customerId = useSelector(customerIdSelector)
  const email = useSelector(emailSelector)
  const marketing = useSelector(marketingSelector)

  // Basket data
  const data = {
    items: basket.items,
    vouchers: basket.vouchers,
    customerId,
    email,
    marketing
  }

  /*
   * Function for checking variant stock
   */
  const fetchStock = async products => {
    const promises = products.map(async p => {
      const stock = await getProduct({
        id: 'gid://shopify/Product/' + p.parentId
      }).then(({ variants }) => {
        // Find variant to check against
        const variantToCheck = variants?.find(v => v.sku == p.variantSku)

        if (!variantToCheck) return false

        // Get stock for variant
        return getVariationStock(variantToCheck, countryCode)
      })

      // If no stock return product object (to use for error)
      return stock ? false : p
    })

    // Wait till all getProduct function are complete, filter undefined array values
    const allStock = await Promise.all(promises).then(result =>
      result.filter(o => o)
    )

    // Set states
    setProductsFailed(allStock)
    setStockChecked(true)
  }

  /*
   * Function ran from basket checkout button
   */
  const checkStock = () => {
    // Clear stock check state
    setStockChecked(false)

    // No items to check
    if (!basket.items) return false

    // Create array of only the data we need
    const products = basket.items.map(e => {
      return {
        title: e.title + ' - ' + e.variant,
        parentId: e.parentId,
        variantSku: e.sku
      }
    })

    // Check API for product stock
    fetchStock(products)
  }

  // Basket data
  const { subtotal, discounts, total, updating } = useSelector(o => ({
    subtotal: o.summary.subtotal,
    total: o.summary.total,
    discounts: o.summary.discounts,
    updating: o.summary.updating
  }))

  /*
   * Function that runs once stock has been checked. Either continue
   * to checkout, or use error data on basket.
   */
  useEffect(() => {
    if (stockChecked && !productsFailed.length) {
      checkout()
    }
  }, [stockChecked])

  /*
   * Function for creating draft order
   */
  const checkout = () => {
    if (inProgress) return

    dispatch(setSubmitted(true))

    checkoutRequest(data).then(order => {
      localStorage.setItem('cart_token', order.draftOrderId)
      dispatch(setSubmitted(false))

      const params = new URLSearchParams()

      if (isGlobalECheckout) {
        params.set('invoice_url', order.invoiceUrl)
        params.set('is_draft_order', '1')
        params.set('draft_order_id', order.draftOrderId)
        closeAll()
        router.push('/international-checkout?' + params)
      } else {
        const gaClientId = window?.ga?.getAll()[0].get('clientId')

        if (gaClientId) {
          params.set('_ga', gaClientId)
        }

        router.push(order.invoiceUrl + '?' + params)
      }
    })
  }

  return {
    checkStock,
    inProgress,
    subtotal,
    discounts,
    total,
    updating,
    productsFailed
  }
}

export default useCheckout
