import Icon from '@/components/common/icon'

export default function Submitted() {
  return (
    <>
      <h4 className="mb-0 text-center pt-10">
        <span className="pr-2 text-uppercase">Please wait</span>
        <Icon icon="Spinner" pulse={true} />
      </h4>
    </>
  )
}
